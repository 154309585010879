import { createRouter, createWebHashHistory } from 'vue-router'
import frame from '../pages/frame.vue';
import mobileFrame from '../pages/mobileFrame.vue';
 //返回当前用户所使用的是什么浏览器
 const userAgent = navigator.userAgent.toLowerCase()
 //移动端
 const isMobile = /mobile/i.test(userAgent);
let routes=[
  {
    path: '/',
    redirect:isMobile?'/mobileFrame/mobileIndex':'/frame/index'
  },
  {
    path: '/frame',
    component:frame,
    children:[
      {
        path: 'index',
        name: 'index',
        component: () => import('@/pages/index.vue')
      }
    ]
  },
  {
    path: '/mobileFrame',
    component:mobileFrame,
    children:[
      {
        path: 'mobileIndex',
        name: 'mobileIndex',
        component: () => import('@/pages/mobileIndex.vue')
      }
    ]
  }
];
const router = createRouter({
  history: createWebHashHistory(),
  routes
})
export default router