<template>
  <router-view></router-view>
</template>

<script setup>
  import { reactive,onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import {useHive} from './store/index.js'
  let store = useHive();
  let router = useRouter();
  let data=reactive({
    isMobile:false,//是否是移动端
  })
  //判断移动端
  const judgeAgent=()=>{
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    data.isMobile =isMobile;
    store.$state.isMobile = isMobile;
    let winWidth = window.innerWidth;
    initScale();
    const currentRoute = router.currentRoute.value;
    if(isMobile || winWidth<750){
      if(currentRoute!='/mobileIndex'){
        router.push('/mobileFrame/mobileIndex');
      }
    }else{
      if(currentRoute!='/frame/index'){
        router.push('/frame/index');
      }
     
    }
  }
  //移动端设置大小
  let initScale=()=>{
    var scale = 750/100;
    var doc = window.document;
    var docEl = doc.documentElement;
    var clientWidth = docEl.clientWidth||window.innerWidth;
    /* 页面宽度大于 设计图文档宽度 时不再放大,小于320时不再缩小 */
    if(clientWidth<=750){
      var oldSize = (clientWidth/scale*2) + 'px';
      docEl.style.fontSize = oldSize;
    }else{
      docEl.style.fontSize =clientWidth / 19.2+'px';
    }
  }
  onMounted(()=>{
    judgeAgent();
    window.addEventListener('resize',()=>{
      judgeAgent();
    })
  })
</script>

<style>

</style>
