<template>
  <div class="frame">
    <Header :isPc="false"></Header>
    <router-view></router-view>
  </div>
</template>
<script setup>
  import {reactive,provide} from "vue";
  import Header from '../components/header.vue';
  let data=reactive({
    showHead:true,//显示导航
  })
  provide('hanldShow',(bol)=>{
    console.log(bol);
    data.showHead=bol;
  })
</script>
<style lang="scss" scoped>
  .frame{
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
</style>