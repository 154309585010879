import {defineStore} from "pinia";
export const useHive = defineStore('hive',{
  state:()=>{
    return {
      isMobile:false,//是否是移动端
    }
  },
  getters:{

  },
  actions:{

  }
})